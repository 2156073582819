class Accordion {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function () {
      // タイトルをクリックすると
      $(".js-accordion-title").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });

    $(function () {
      //家づくり相談シングルページ
      $(".p-counseling-detail__acco").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });

      $(".p-counseling-detail__acco-item").on("click", function() {
        $(".p-counseling-detail__acco-list").slideUp(300);
        $(".p-counseling-detail").removeClass("white-out");
        // $(function(){
        //   setTimeout(function(){
        //     $(".p-counseling-detail").addClass("white-out");
        //   },500);
        // });
      })


    });

    $(function () {
      //ヘッダー
      $(".drawer-works-acco").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });

    $(function () {
      //フッター
      $(".f-menu__acco-event").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });

  }
}
